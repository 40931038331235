import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>

      <section className="privacy-content">
        <h2>1. Personal Data Collection</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          sodales ullamcorper vehicula.
        </p>

        <h2>2. Use of Personal Data</h2>
        <p>
          Donec porttitor augue consequat, ornare metus sed, mattis nunc.
          Curabitur eget turpis quis risus luctus scelerisque.
        </p>

        <h2>3. Data Protection</h2>
        <p>
          Morbi dapibus, ligula ac commodo dictum, sapien tortor volutpat
          tellus, a dictum leo elit et justo.
        </p>

        <h2>4. Cookies</h2>
        <p>
          Nulla volutpat semper quam nec venenatis. Sed sit amet sem ac augue
          tincidunt condimentum.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
