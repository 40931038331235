import React, { useState } from "react";

interface FormData {
  firstName: string;
  lastName: string;
  edmStyles: { [key: string]: boolean };
  otherStyles: { [key: string]: boolean };
  futureInterest: string;
  phoneNumber: string;
  agreeToTerms: boolean;
}

const BoogieForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    edmStyles: {
      "Pop Dance": false,
      House: false,
      Experimental: false,
      Bass: false,
      Trap: false,
      Melodic: false,
      Techno: false,
      Minimal: false,
      "I don't like EDM": false,
    },
    otherStyles: {
      None: false,
      Country: false,
      Pop: false,
      Rock: false,
      Alternative: false,
      "Hip Hop": false,
    },
    futureInterest: "",
    phoneNumber: "",
    agreeToTerms: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (formData.edmStyles.hasOwnProperty(name)) {
      setFormData((prev) => ({
        ...prev,
        edmStyles: { ...prev.edmStyles, [name]: checked },
      }));
    } else if (formData.otherStyles.hasOwnProperty(name)) {
      setFormData((prev) => ({
        ...prev,
        otherStyles: { ...prev.otherStyles, [name]: checked },
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  return (
    <div className="boogie-form">
      <h1>Let's Boogie</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
        />

        <h2>Please select the styles of EDM that you like:</h2>

        {Object.entries(formData.edmStyles).map(([style, checked]) => (
          <label key={style}>
            <input
              type="checkbox"
              name={style}
              checked={checked}
              onChange={handleCheckboxChange}
            />
            {style}
          </label>
        ))}

        <h2>We currently only support EDM, what else are you interested in?</h2>

        {Object.entries(formData.otherStyles).map(([style, checked]) => (
          <label key={style}>
            <input
              type="checkbox"
              name={style}
              checked={checked}
              onChange={handleCheckboxChange}
            />
            {style}
          </label>
        ))}

        <h2>Phone Verification</h2>
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          required
        />
        <small>Format: 123-456-7890</small>

        <label>
          <input
            type="checkbox"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                agreeToTerms: e.target.checked,
              }))
            }
          />
          Yes, I would like to receive text message updates from Boogie Juice
          according to the Terms of Service
        </label>

        <div className="submit-section">
          <p className="user-count">Join 600+ in Austin</p>
          <button type="submit" className="sign-up">
            Sign Up
          </button>
        </div>

        <div className="disclaimer">
          By providing my phone number, I agree that updates & offers may be
          sent to me from Boogie Juice via text message at the phone number
          provided & agree to Boogie Juice terms/privacy:
          <a
            href="https://boogiejuicetext.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            boogiejuicetext.com/terms
          </a>
          . Consent is not a condition to purchase. Standard message frequency
          may vary. Standard msg. & data rates may apply. Reply HELP for help.
          Opt-out any time by replying STOP to cancel. See Boogie Juice contact
          information below.
        </div>
      </form>
    </div>
  );
};

export default BoogieForm;
