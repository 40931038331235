import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

type TopNavProps = {
  title: string;
};

const TopNav: React.FC<TopNavProps> = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const activeLink = location.pathname;

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuClick = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="topnav">
      <div className="left"></div>
      <h1>{title}</h1>
      <div className="right">
        <div className="menu" onClick={handleMenuClick}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        {menuOpen && (
          <div className="dropdown" onClick={handleCloseMenu}>
            <span className="close-icon" onClick={handleMenuClick}>
              X
            </span>
            <div className="menu-content" onClick={(e) => e.stopPropagation()}>
              <Link
                to="/"
                className={activeLink === "/" ? "active" : ""}
                onClick={handleMenuClick}
              >
                Home
              </Link>
              <Link
                to="/signup"
                className={activeLink.includes("/signup") ? "active" : ""}
                onClick={handleMenuClick}
              >
                Sign Up
              </Link>
              <Link
                to="/learn"
                className={activeLink.includes("/learn") ? "active" : ""}
                onClick={handleMenuClick}
              >
                About Us
              </Link>
              <Link
                to="/terms"
                className={activeLink.includes("/terms") ? "active" : ""}
                onClick={handleMenuClick}
              >
                Terms
              </Link>
              <Link
                to="/privacy"
                className={activeLink.includes("/privacy") ? "active" : ""}
                onClick={handleMenuClick}
              >
                Privacy
              </Link>
              <Link
                to="/contact"
                className={activeLink.includes("/contact") ? "active" : ""}
                onClick={handleMenuClick}
              >
                Contact
              </Link>
              <Link to="/signup" onClick={handleMenuClick}>
                <button className="sign-up">Sign Up</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNav;
