import React from "react";

const TermsOfService: React.FC = () => {
  return (
    <div className="tos-container">
      <h1>Terms of Service</h1>

      <section className="tos-content">
        <h2>1. Introduction</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          sodales ullamcorper vehicula.
        </p>

        <h2>2. User Responsibilities</h2>
        <p>
          Donec porttitor augue consequat, ornare metus sed, mattis nunc.
          Curabitur eget turpis quis risus luctus scelerisque.
        </p>

        <h2>3. Privacy</h2>
        <p>
          Morbi dapibus, ligula ac commodo dictum, sapien tortor volutpat
          tellus, a dictum leo elit et justo.
        </p>

        <h2>4. Liability</h2>
        <p>
          Nulla volutpat semper quam nec venenatis. Sed sit amet sem ac augue
          tincidunt condimentum.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
