import React from "react";

interface InfoBoxProps {
  text: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ text }) => {
  return <div className="info-box">{text}</div>;
};

export default InfoBox;
