import { Link } from "react-router-dom";
import InfoBox from "../components/InfoBox";

export default function Learn() {
  return (
    <div className="info-page">
      <h1>This you?</h1>
      <div className="info-boxes">
        <InfoBox text="How many IG pages, group chats, emails, and blogs do you follow to keep up with the music scene?" />
        <InfoBox text="The average person we talked to follows 14.  To fix this, we built Boogie Juice!" />
        <InfoBox text="Boogie Juice is the friend who knows the music you like and sends you the events you'll love." />
        <InfoBox text="From your favorite headliner to IYKYK afters.  Focus on your week, we'll focus on your weekend." />
      </div>
      <Link to="/signup">
        <button className="sign-up">Sign up here</button>
      </Link>
    </div>
  );
}
