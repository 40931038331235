import React from "react";
import { Link } from "react-router-dom";

const TitleSplash: React.FC = () => {
  return (
    <div className="title-splash">
      <h1>Dance more, search less</h1>
      <h2>
        Receive a <span className="highlight">FREE</span> custom weekly text
        with recently announced concerts and upcoming events, based on your
        music taste
      </h2>
      <h3>
        Join 600+ <span className="highlight2">Austin music lovers</span>
      </h3>
      <Link to="signup">
        <button className="sign-up">Sign Up</button>
      </Link>
      <Link to="/learn">
        <button className="learn">Learn More</button>
      </Link>
    </div>
  );
};

export default TitleSplash;
