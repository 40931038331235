import React from "react";

const ContactUs: React.FC = () => {
  return (
    <div className="contact-container">
      <h1>Questions?</h1>

      <h2>
        Email us at{" "}
        <a href="mailto:its@boogiejuicetext.com">its@boogiejuicetext.com</a>
      </h2>

      <h2>Last modified: 8/9/23</h2>

      <h2>Thank you!</h2>
    </div>
  );
};

export default ContactUs;
